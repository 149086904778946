<template>
  <v-card class="mx-auto" :disabled="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'providers',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title +
              (tbl.length > 0 ? ' (' + tbl.length + ')' : '')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-text-field
            label="Código"
            v-model="code"
            maxlength="30"
            dense
            type="text"
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-text-field
            label="Descripción"
            v-model="description"
            maxlength="250"
            dense
            type="text"
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col v-if="tbl.length == 0" cols="12" xs="12" md="6">
          <v-btn
            block
            small
            color="success"
            @click.prevent="getTbl"
            :loading="ldg"
            :disabled="code == '' && description == ''"
          >
            Buscar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="12" xs="12" md="6">
          <v-btn block small color="" @click.prevent="tbl = []">
            Cambiar parametros
            <v-icon right> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="tbl_srch"
            append-icon="mdi-magnify"
            label="Buscar en tabla..."
            single-line
            hide-details
            dense
            :disabled="tbl.length == 0"
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_hdrs"
            :search="tbl_srch"
            :items="tbl"
            :loading="ldg"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.key="{ item }">
              <b v-text="item.key + 1" />
            </template>
            <template v-slot:item.amount="{ item }">
              {{ numberFormat(item.amount) }}
            </template>
            <!-- <template v-slot:item.action="{ item }">
              <div class="text-right">
                <v-tooltip v-if="log.permissions.rss_rs_refounds.update" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :color="item.rs_administratives > 0 ? 'indigo' : 'grey'"
                      x-small
                      icon
                      dark
                      class="ml-1"
                      :to="{
                        name: 'servicios.rs_refounds.administratives',
                        params: { id: item.id },
                      }"
                    >
                      <v-icon> mdi-note-multiple </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Seg. Administrativo'" />
                </v-tooltip>
              </div>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken, numberFormat, msgAlert } from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    FaqDlg,
  },
  data() {
    return {
      numberFormat: numberFormat,
      log: this.$store.getters.getLogin,
      ldg: false,
      tbl: [],
      tbl_hdrs: [
        {
          text: "#",
          value: "key",
          filterable: false,
          width: "50",
        },
        {
          text: "Proveedor",
          value: "provider.trade_name",
          filterable: true,
        },
        {
          text: "Tipo",
          value: "benefit_type.benefit_type",
          filterable: true,
        },
        {
          text: "Código",
          value: "code",
          filterable: true,
        },
        {
          text: "Descripción",
          value: "description",
          filterable: true,
        },
        {
          text: "Sub-Tipo",
          value: "benefit_detail_type.benefit_type",
          filterable: true,
        },
        {
          text: "Definición",
          value: "definition",
          filterable: true,
        },
        {
          text: "Monto",
          value: "amount",
          filterable: true,
        },
        // {
        //   text: "",
        //   value: "action",
        //   width: "130",
        //   sortable: false,
        //   fixed: true,
        // },
      ],
      tbl_srch: "",
      code: "",
      description: "",
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTbl() {
      this.ldg = true;
      this.tbl = [];
      this.tbl_srch = "";

      Axios.get(
        URL_API +
          "/providers/search_benefits" +
          "?code=" +
          this.code +
          "&description=" +
          this.description,
        headersToken(this.log.token)
      ).then((res) => {
        this.tbl = res.data.data;
        this.ldg = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.log.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });
  },
};
</script>